import React, {
  Fragment,
  createContext,
  useContext,
  isValidElement,
} from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

export const LocaleContext = createContext();

export const LocaleLink = ({ to, ...props }) => {
  const { locale, defaultLocale } = useContext(LocaleContext);
  return (
    <Link
      to={
        to
          ? `${locale === defaultLocale ? "/" : `/${locale}/`}${to.replace(
              /^\//,
              ""
            )}`
          : to
      }
      {...props}
    />
  );
};

export const useI18n = (id) => {
  const { locale, defaultLocale } = useContext(LocaleContext);
  const {
    strapi: { localizations: { data: localizations = [] } = {} },
  } = useStaticQuery(graphql`
    query {
      strapi {
        localizations(locale: "all") {
          data {
            attributes {
              key
              content
              locale
            }
          }
        }
      }
    }
  `);

  return (
    (
      localizations.find(
        ({ attributes: { key, locale: l } }) => key === id && l === locale
      ) ||
      localizations.find(
        ({ attributes: { key, locale: l } }) =>
          key === id && l === defaultLocale
      )
    )?.attributes?.content ?? id
  );
};

export default ({ component: C = Fragment, id, ...props }) => {
  const string = useI18n(id);

  return (
    <C {...props} {...(isValidElement(C) ? {} : { "data-i18n-id": id })}>
      {string}
    </C>
  );
};
