import Color from "color";
import { isClient } from "./dev";

const withTheme = ({ primaryColor: p, secondaryColor: s }) => {
  const theme = {
    "--color-primary-main": p,
    "--color-primary-light": Color(p).lighten(0.5).string(),
    "--color-primary-contrast-text": Color(p).isLight() ? "#000" : "#fff",
    "--color-secondary-main": s,
    "--color-secondary-light": Color(s).lighten(0.5).string(),
    "--color-secondary-contrast-text": Color(s).isLight() ? "#000" : "#fff",
  };
  if (isClient) {
    Object.entries(theme).forEach((p) =>
      document.documentElement.style.setProperty(...p)
    );
  }
  return theme;
};

export default withTheme;
