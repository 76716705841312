import React from "react";
import * as css from "../styles/Autocomplete.module.scss";
import Search from "@mdi/svg/svg/magnify.svg";
import { classs } from "../utils/utils";
import I18n, { useI18n } from "../components/I18n";

export default ({ onChange, items = [], placeholder }) => {
  const i18nPlaceholder = useI18n("search.search");

  return (
    <div className={css.Autocomplete} tabIndex="0">
      <Search />
      <input
        type="text"
        onChange={onChange}
        placeholder={placeholder ?? i18nPlaceholder}
      />
      <div>
        {(items.length === 0 ||
          !items.some(({ values }) => values?.length > 0)) && (
          <I18n id="search.no_result" component="span" />
        )}
        {items
          .filter(({ value, values }) => value || values.length > 0)
          .map(({ key, values, value }) => (
            <div key={key} className={classs({ group: values })}>
              {values ? <span>{key}</span> : null}
              {values
                ? values.map(({ key, value }) => <div key={key}>{value}</div>)
                : value}
            </div>
          ))}
      </div>
    </div>
  );
};
