import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Seo from "../components/Seo";

import { isClient } from "../utils/dev.js";
import { classs, debounce } from "../utils/utils.js";
import withTheme from "../utils/withTheme.js";

import "../styles/root.css";
import * as css from "../styles/Layout.module.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Menu from "@mdi/svg/svg/menu.svg";
import ButtonBase from "./ButtonBase";

import Youtube from "@mdi/svg/svg/youtube.svg";
import Twitter from "@mdi/svg/svg/twitter.svg";
import Facebook from "@mdi/svg/svg/facebook.svg";
import Instagram from "@mdi/svg/svg/instagram.svg";
import I18n, { LocaleContext, LocaleLink } from "./I18n";
import { GraphQLClient, gql } from "graphql-request";
import Autocomplete from "./Autocomplete";

const withLangNav = false;

const getHelmetBodyStyleAttributeValue = (style) =>
  isClient
    ? Object.keys(style)
        .map((key) => `${key}: ${style[key]}`)
        .join(";")
    : style;

const Icon = ({ source, className }) => {
  const icons = {
    twitter: Twitter,
    facebook: Facebook,
    instagram: Instagram,
    youtube: Youtube,
  };
  const Source = icons[source];
  return <Source className={className} />;
};

const isDefaultLocale = (locale, locales) =>
  locales.find(({ attributes: { code } }) => code === locale).isDefault;
const withLocale = (path, locale, locales) =>
  isDefaultLocale(locale, locales) ? path : `/${locale}${path}`;

const client = new GraphQLClient("https://cms.pelleas.ariane.app/graphql", {
  headers: {
    Authorization: `Bearer 53515da6c86af0c63237f832be245755067321262dab78a19f102b08f3fadfa85688a39a98729c92bb38ab6e33552779e8040b4fbd8678541c4a54e5bc08a2b788898cc44a85d711ceb65490c38fe7f135ea4540b2f2ebbe44e49ab94e5592c263ee6e41c0e3fc0df0bbf42089009d2678b41be65ad7468999080f1db1853d27`,
  },
});

const Layout = ({
  creation = {},
  locale,
  locales,
  className,
  pathname = "",
  children,
  meta,
  jsonLd,
  canonical,
  title,
}) => {
  const styles = withTheme(creation);
  const [open, setOpen] = useState(false);
  const {
    strapi: {
      about: {
        data: {
          attributes: { name, address, telephone },
          socials,
        },
      } = {},
    },
  } = useStaticQuery(graphql`
    query {
      strapi {
        about {
          data {
            attributes {
              name
              address
              telephone: phone
            }
            socials: attributes {
              facebook: facebook_url
              twitter: twitter_url
              instagram: instagram_url
              youtube: youtube_url
            }
          }
        }
      }
    }
  `);

  const currentPath = isDefaultLocale(locale, locales)
    ? "/" + pathname.split("/")[1] ?? ""
    : "/" + pathname.split("/")[2] ?? "";
  const altLocale = locales
    .filter(({ attributes: { code } }) => code !== locale)
    .pop()?.attributes?.code;
  const defaultLocale = locales.find(
    ({ attributes: { isDefault } }) => isDefault
  ).attributes.code;

  const [
    {
      creations: { data: creations = [] } = {},
      artists: { data: artists = [] } = {},
    },
    setSearchResult,
  ] = useState({});
  const search = debounce((value) => {
    value?.length > 0 &&
      client
        .request(
          gql`
            query ($value: String!) {
              artists(
                pagination: { limit: 10 }
                filters: {
                  slug: { notNull: true }
                  or: [
                    { first_name: { containsi: $value } }
                    { last_name: { containsi: $value } }
                    { slug: { containsi: $value } }
                    { tri_name: { containsi: $value } }
                  ]
                }
              ) {
                data {
                  attributes {
                    slug
                    first_name
                    last_name
                  }
                }
                typename: __typename
              }
              creations(
                pagination: { limit: 10 }
                filters: {
                  or: [
                    { title: { containsi: $value } }
                    { slug: { containsi: $value } }
                  ]
                  slug: { notNull: true }
                }
              ) {
                data {
                  attributes {
                    slug
                    title
                    productor
                  }
                }
                typename: __typename
              }
            }
          `,
          { value }
        )
        .then((data) => setSearchResult(data));
  });

  return (
    <LocaleContext.Provider value={{ locale, defaultLocale }}>
      <Helmet>
        <html lang={locale} style={getHelmetBodyStyleAttributeValue(styles)} />
      </Helmet>
      <Seo title={title} meta={meta} jsonLd={jsonLd} canonical={canonical} />
      <header className={css.Header}>
        <ButtonBase component={LocaleLink} to="/">
          <StaticImage
            src="../assets/images/logo/logo_pelleas.png"
            alt={name}
            placeholder="blurred"
            layout="fixed"
            height={43}
          />
        </ButtonBase>
        <Menu className="menu" onClick={() => setOpen((s) => !s)} />
        <nav className={classs({ open })}>
          <ul>
            {Object.entries(socials ?? {})
              .filter(([, url]) => url)
              .map(([social, url]) => (
                <ButtonBase
                  key={social}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon className="source" source={social} />
                </ButtonBase>
              ))}
          </ul>
          <Autocomplete
            onChange={({ target: { value } }) => search(value)}
            items={[
              {
                key: "Créations",
                values: creations.map(
                  ({ attributes: { title: value, slug: key, productor } }) => ({
                    key,
                    value: (
                      <ButtonBase
                        key={key}
                        component={LocaleLink}
                        to={
                          productor === "balthuslab"
                            ? `/artnum/${key}`
                            : `/film/${key}`
                        }
                      >
                        {value}
                      </ButtonBase>
                    ),
                  })
                ),
              },
              {
                key: "Artistes",
                values: artists.map(
                  ({ attributes: { first_name, last_name, slug: key } }) => ({
                    key,
                    value: (
                      <ButtonBase
                        component={LocaleLink}
                        to={`/personne/${key}`}
                      >
                        {[first_name, last_name].filter((v) => v).join(" ")}
                      </ButtonBase>
                    ),
                  })
                ),
              },
            ]}
          />
          <ul>
            {[
              { i18n: "title.home", path: "/" },
              { i18n: "title.films", path: "/film" },
              { i18n: "title.directors", path: "/realisateur" },
              { i18n: "title.netarts", path: "/artnum" },
              { i18n: "title.news", path: "/actualite" },
              { i18n: "title.contact", path: "/contact" },
              ...(withLangNav && altLocale
                ? [
                    {
                      title: altLocale.toUpperCase(),
                      path: withLocale(currentPath, altLocale, locales),
                      component: Link,
                      active: false,
                    },
                  ]
                : []),
            ].map(
              ({ i18n, title, path, active, component: C = LocaleLink }) => (
                <li
                  key={path}
                  className={classs({
                    active: active ?? currentPath === path,
                  })}
                >
                  <ButtonBase component={C} to={path}>
                    {title ?? <I18n id={i18n} />}
                  </ButtonBase>
                </li>
              )
            )}
          </ul>
        </nav>
      </header>
      <div className={className}>{children}</div>
      <footer className={css.Footer}>
        <div>{name}</div>
        <div>{address}</div>
        <div>{telephone}</div>
      </footer>
    </LocaleContext.Provider>
  );
};

export default Layout;
