import React from "react";
import * as css from "../styles/ButtonBase.module.scss";
import { classs, multiple, buildHandleEnterKeyPress } from "../utils/utils";

const createRipple = (event) => {
  const button = event.currentTarget;
  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  const { left, top } = button.getBoundingClientRect();
  Object.assign(circle.style, {
    width: `${diameter}px`,
    height: `${diameter}px`,
    left: `${event.pageX - window.scrollX - (left + radius)}px`,
    top: `${event.pageY - window.scrollY - (top + radius)}px`,
  });
  circle.classList.add(css.ripple);
  const ripple = button.getElementsByClassName(css.ripple)[0];
  if (ripple) ripple.remove();
  button.appendChild(circle);
};

const ButtonBase = ({
  children,
  className,
  component: Component = "button",
  onClick = () => null,
  ...props
}) => (
  <Component
    className={classs(css.ButtonBase, className)}
    onClick={multiple(createRipple, onClick)}
    onKeyPress={buildHandleEnterKeyPress(multiple(createRipple, onClick))}
    {...(Component === "div" ? { role: "button" } : {})}
    {...props}
  >
    {children}
  </Component>
);

export default ButtonBase;
