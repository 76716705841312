import dev from "../utils/dev.js";

export const formatS3URL = ({ key, bucket, region = "eu-west-1" }) =>
  `https://${bucket}.s3-${region}.amazonaws.com/${encodeURIComponent(key)}`;

export const andList = (arr, prefix) =>
  arr
    ? arr.length === 0
      ? ""
      : [
          prefix,
          arr.reduce(
            (a, b, i, array) => a + (i < array.length - 1 ? ", " : " et ") + b
          ),
        ]
          .filter((v) => v)
          .join(" ")
    : arr;

export const sum = (array, keyGetter = (v) => v) =>
  array.reduce((r, obj, i, arr) => r + (keyGetter(obj, i, arr) || 0), 0);

export const createCounter = (startAt = 0) =>
  (
    (i) => () =>
      i++
  )(startAt);

export const tryc = (toEval, fallback) => {
  try {
    return toEval();
  } catch {
    return fallback;
  }
};

export const multiple =
  (...fns) =>
  (e) =>
    fns.forEach(async (fn) => typeof fn === "function" && fn(e));

export const throwErr = (e) => {
  throw e;
};

export const getMinMax = (rows) => [Math.min(...rows), Math.max(...rows)];

export const countDaysBetween = (d1, d2, abs = true) =>
  (abs ? Math.abs : (v) => v)(new Date(d2).getTime() - new Date(d1).getTime()) /
  (1000 * 3600 * 24);

export const daysBetween = (d1, d2) =>
  isFinite(d1) && isFinite(d2)
    ? [
        ...Array(
          Math.floor(countDaysBetween(new Date(d1), new Date(d2)))
        ).keys(),
      ].map(
        (days) => new Date(new Date(d1).setDate(new Date(d1).getDate() + days))
      )
    : [];

export const sameDay = (first, second) =>
  new Date(first).getFullYear() === new Date(second).getFullYear() &&
  new Date(first).getMonth() === new Date(second).getMonth() &&
  new Date(first).getDate() === new Date(second).getDate();

export const stringToDate = (string, sep = "/") => {
  const [MM, DD, YYYY] = string.split(sep);
  return new Date(YYYY, MM - 1, DD);
};

export const getDay = (d) => new Date(new Date(d).setHours(0, 0, 0, 0));

export const toHHMM = (date) =>
  [new Date(date).getUTCHours(), new Date(date).getUTCMinutes()]
    .map(String)
    .map((n) => n.padStart(2, "0"))
    .join("h");

export const addMinutes = (date, minutes) =>
  new Date(new Date(date).getTime() + minutes * 60 * 1000);

export const addDays = (date, days) =>
  new Date(new Date(date).getTime() + days * 24 * 60 * 60 * 1000);

export const addWeeks = (date, weeks) => addDays(date, 7 * weeks);

export const toLongDateString = (date) =>
  date
    ? new Date(date).toLocaleDateString("fr-FR", {
        weekday: "long",
        month: "long",
        day: "numeric",
      })
    : date;

export const isObject = (obj) => obj === Object(obj);

export const flattenObject = (obj, prefix = "") =>
  isObject(obj)
    ? Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + "_" : "";
        if (isObject(obj[k]))
          Object.assign(acc, flattenObject(obj[k], pre + k));
        else acc[pre + k] = obj[k];
        return acc;
      }, {})
    : obj;

export const toObject = (value, term = "value") =>
  isObject(value) ? value : { [term]: JSON.stringify(value) };

export const noopLog = (v) => dev.console.log(v) || v;

const cinemapId = 0; //TODO
export const coversWithDeviceType = (
  covers,
  defaultCovers,
  cinemapIdsByCoverId
) =>
  Object.fromEntries(
    ["desktop", "mobile", "tablet"]
      .map((deviceType) => [
        deviceType,
        covers
          .filter(({ deviceType: v }) => v === deviceType)
          .find(({ id }) =>
            cinemapIdsByCoverId[id].includes(String(cinemapId))
          ),
      ])
      .map(([deviceType, poster]) => [
        deviceType,
        poster
          ? poster
          : defaultCovers.find(({ deviceType: v }) => v === deviceType) ||
            defaultCovers[0],
      ])
  );

export const getFile = ({
  image: {
    media: { ...media },
  },
  ...o
}) => ({ ...o, ...media });

export const unique = (fn) => (o, i, arr) =>
  arr.findIndex((oo) => fn(o) === fn(oo)) === i;

export const classs = (...args) =>
  args
    .flatMap((object) => {
      if (!object) return null;
      if (typeof object === "string") {
        return object;
      }
      return Object.entries(object).reduce(
        (str, [name, bool]) => (bool && name ? [...str, name] : str),
        []
      );
    })
    .filter((v) => v)
    .join(" ");

export const buildHandleEnterKeyPress =
  (onClick) =>
  ({ key }) =>
    key === "Enter" && onClick();

export const groupBy = (data, field, { asList = false } = {}) => {
  const result = (data || []).reduce((accumulator, row) => {
    const groupby = typeof field === "function" ? field(row) : field;
    accumulator[groupby] = accumulator[groupby] || [];
    accumulator[groupby].push(row);
    return accumulator;
  }, {});
  return asList ? Object.entries(result) : result;
};

export const groupByList = (data, field, { ...o } = {}) =>
  groupBy(data, field, { asList: true, ...o });

export const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const { sin, cos, sqrt, PI, asin } = Math;
  const R = 6378137;
  const squared = (x) => x * x;
  const toRad = (x) => (x * PI) / 180.0;
  const hav = (x) => squared(sin(x / 2));
  const [aLat, aLon, bLat, bLon] = [lat1, lon1, lat2, lon2].map(toRad);

  // hav(theta) = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon)
  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon);
  return (2 * R * asin(sqrt(ht))) / 1000;
};

export const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};

export const getCinema = ({
  name,
  location: {
    lat,
    lon,
    address,
    address: { display_text: text },
  },
}) => ({
  ...address,
  lat,
  lon,
  name,
  text,
});
