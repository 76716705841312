import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { LocaleContext } from "./I18n";

export const JsonLd = ({ json = {} }) => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(json)}</script>
  </Helmet>
);

export default ({ title, meta, jsonLd, canonical }) => {
  const { locale } = useContext(LocaleContext);
  const {
    strapi: {
      about: {
        data: {
          attributes: {
            name,
            description,
            twitterId = "LesFilmsPelleas",
            url = "https://lesfilmspelleas.com",
            streetAddress,
            postalCode,
            city: addressLocality,
            country: addressCountry,
            telephone,
            unifranceId,
            imdbId,
            wikipediaId,
            cineEuropaId,
            allocineId,
            facebookId,
            youtubeId,
            instagramId,
          },
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      strapi {
        about(locale: "fr") {
          data {
            attributes {
              name
              description
              email
              address
              phone
              projectsText: projects_text
            }
          }
        }
      }
    }
  `);

  const organization = {
    "@type": "Organization",
    name,
    url,
    address: {
      "@type": "PostalAddress",
      streetAddress,
      postalCode,
      addressLocality,
      addressCountry,
      telephone,
    },
    sameAs: [
      imdbId ? `https://www.imdb.com/company/${imdbId}` : null,
      wikipediaId ? `https://fr.wikipedia.org/wiki/${wikipediaId}` : null,
      allocineId
        ? `http://www.allocine.fr/societe/fichesociete-${allocineId}/`
        : null,
      cineEuropaId
        ? `https://cineuropa.org/fr/prodcompany/${cineEuropaId}/`
        : null,
      unifranceId
        ? `https://en.unifrance.org/directories/company/${unifranceId}/`
        : null,
      twitterId ? `https://twitter.com/${twitterId}` : null,
      facebookId ? `https://www.facebook.com/${facebookId}` : null,
      youtubeId ? `https://www.youtube.com/channel/${youtubeId}` : null,
      instagramId ? `https://www.instagram.com/${instagramId}` : null,
    ].filter((v) => v),
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: locale,
        }}
        title={title ?? name}
        titleTemplate={title ? `%s | ${name}` : undefined}
        meta={(typeof meta === "function"
          ? meta
          : (a) => [...a, ...(meta ?? [])].filter((v) => v))(
          [
            { name: "description", content: description },
            { property: "og:site_name", content: name },
            {
              name: "twitter:site",
              content: twitterId ? `@${twitterId}` : null,
            },
            {
              name: `twitter:creator`,
              content: twitterId ? `@${twitterId}` : null,
            },
          ],
          { url }
        )
          .reverse()
          .filter(
            (v, i, a) =>
              a
                .map((x) => x.name ?? x.property)
                .indexOf(v.name ?? v.property) == i
          )
          .reverse()}
      >
        <link
          rel="canonical"
          href={new URL(...[canonical, url].filter((v) => v))}
        />
      </Helmet>
      {(typeof jsonLd === "function"
        ? jsonLd
        : (a) => [...a, jsonLd].flat(10).filter((v) => v))([organization], {
        url,
        organization,
      }).map((json, i) => (
        <JsonLd key={i} json={json} />
      ))}
    </>
  );
};
